export const experienceData = [
	{
		row: 1,
		heading: "Vinnovera AB",
		description: "Frontendutvecklare, LIA 8 veckor",
		date: "Aug 2021 - Okt 2021"
	},
	{
		row: 2,
		heading: "Svevia AB",
		description: "Yrkesarbetare Trafikanordningar, Tunneltvätt och Drift",
		date: "Apr 2015 - Pågående"
	},
	{
		row: 3,
		heading: "Mankan Entreprenad",
		description: "Kranbilschaufför. Arbetade för underentreprenör med allt inom vägarbete hos Svevia och Bäckström AB.",
		date: "Jun 2013 – Apr 2015"
	},
	{
		row: 4,
		heading: "TÅV AB",
		description: "Kranbilschaufför. Arbetade med att tömma återvinningsbehållare med kranbil.",
		date: "Jan 2013 – Jun 2013"
	},
	{
		row: 5,
		heading: "Kompetenser",
		description: "HTML, CSS, JavaScript, React, Vue, Gatsby, WCAG, Java, Figma, Adobe XD, Agilt arbete, Musikproduktion, Cubase, Photoshop",
		date: null
	}
]