import React from "react"
import Information from "../components/Information"
import Layout from "../components/Layout"
import { experienceData } from '../data/experience'

const ExperiencePage = () => {
	return (
		<Layout>
		<main className="tone-in">
			<Information content={experienceData} />
		</main>
		</Layout>
	)
}

export default ExperiencePage