import React from "react"

const Information = ({ content }) => {

	function buildTable() {
		return (
		<tbody> 
			{	content.map(c => (
					<tr className="tablerow" key={c.row} >
						<td>{ c.heading }</td>
						<td>{ c.description }</td>
						<td>{ c.date ? c.date : null }</td>
					</tr>
				)) }
		</tbody>
		)
	}
	const table = buildTable()

	return (
		<table className="infotable">
			{ table }
		</table>
	)
}

export default Information