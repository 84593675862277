import React from 'react'

const Footer = () => {
	return (
		<footer className="tone-in">
			<a href="https://www.linkedin.com/in/stefan-sj%C3%B6man/" target="_blank" rel="noreferrer">
				<div className="linked-in"></div>
			</a>
			<a href="mailto:info@stefansjoman.se">info@stefansjoman.se</a>
		</footer>
	)
}

export default Footer