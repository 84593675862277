import React from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import '../css/style.scss'

const Layout = ({ children }) => {
	return (
		<>
			<Header alignCenter={"header-top"} />
			<Helmet>
				<html lang="sv" amp />
				<meta charSet="utf-8" />
				<title>Stefan Sjöman</title>
				<meta name="icon" href="../assets/icons/SS_192x192.png" />
				<meta name="description" content="Stefan Sjöman CV Portfolio Frontend Developer" />
				<link rel="canonical" href="https://stefansjoman.se" />
			</Helmet>
			{ children }
			<Footer />
		</>
	)
}

export default Layout